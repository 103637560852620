import React, { useState, useRef } from "react"
import {
  Box,
  Grid,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  HStack
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { VscWarning } from "react-icons/vsc";

import styled from '@emotion/styled'

import { useForm } from "react-hook-form"

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  message: yup.string().required("A message is required"),
  email: yup
    .string()
    .required()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
});

const errorStyles = {
  color: "#bf1650",
  fontSize: "1rem",
};

const ContactForm = () => {
    const [state, setState] = React.useState({})
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const sendMailgunFormData = async (data) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
        console.log('something went wrong with the ref');
    }

    const formData = new FormData(formRef.current);


    // Use console.log() to see the data that is being sent to the server
    console.log(Array.from(formData.entries()));

   fetch('/.netlify/functions/send-contact-form', {
           method: 'POST',
           body: formData,
           // don't send headers due to boundary problem 
       })
       // Convering the response to JSON breaks status code
       .then(res => {
           console.log(res)
              if (res.status === 200) {
                    setSucceeded(true)
                    setProcessing(false)
                    reset()
                } else {
                    setSucceeded(false)
                    console.log("network error")
                    alert("Network error. Please try again later.")
                }
       }
    )
};

  const ContactGrid = styled(Grid)`
    grid-template-columns: 1fr;

    @media(min-width: 48em) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem;
    }
    
  `;
  
  const formRef = useRef(null);

  const SuccessMessage = () => (
      <Box my="12">      
          <Heading as="h4" w="100%" textAlign="center">Thank you for your submission.<br />
          We'll be in touch soon!</Heading>
      </Box>
  )


  return (
    <>
      <Box  px={4} mb={8}>
      { succeeded ? <SuccessMessage /> : (
        <>
        {/* <Heading as="h3" fontSize="xl" color="steel.700">Send us a message:</Heading> */}
        <form onSubmit={handleSubmit(sendMailgunFormData)} name="contact-form" ref={formRef}>
        <input type="hidden" name="form-name" value="contact-form" />
          <ContactGrid className="contactGrid"  py={4}>
            <Box w="100%">
              <FormControl id="name">
                <FormLabel color="shadow.800">Your Name<Text color="red.200" as="span">*</Text></FormLabel>
                <Input type="text"
                    {...register("name")}
                />
                {errors.name && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.name?.message}</Box>
                  </HStack>
                )}
              </FormControl>
            </Box>
            <Box w="100%">
              <FormControl id="companyName">
                <FormLabel color="shadow.800">Company Name</FormLabel>
                <Input type="text" 
                    {...register("companyName")}
                 />
              </FormControl>
            </Box>
            <Box w="100%">
              <FormControl id="phone">
                <FormLabel color="shadow.800">Phone Number</FormLabel>
                <Input type="tel" 
                    {...register("phone")}
                />
                               {errors.phone && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.phone?.message}</Box>
                  </HStack>
                )}
              </FormControl>
            </Box>
            <Box w="100%">
              <FormControl id="email">
                <FormLabel color="shadow.800">Email address<Text color="red.200" as="span">*</Text></FormLabel>
                <Input type="email"
                  name="email"
                   {...register("email")}
                   />
                  {errors.email && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.email?.message}</Box>
                  </HStack>
                )}
              </FormControl>
            </Box>
          </ContactGrid>
          <Box>
        <FormControl id="message" mt={4}>
          <FormLabel color="shadow.800">Your Message<Text color="red.200" as="span">*</Text></FormLabel>
            <Textarea placeholder=""
                {...register("message")}
                />
                                {errors.message && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.message?.message}</Box>
                  </HStack>
                )}
            </FormControl>
            </Box>
          <Button variant="gold-button" type="submit" mt={4} disabled={processing} >
              {processing ? "Submitting..." : "Submit" }
          </Button>
        </form>
        </>
      )
}
      </Box>
    </>
  )
}

export default ContactForm
